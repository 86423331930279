import { graphql, PageProps } from "gatsby"
import React from "react"
import Layout from "components/Layout"
import SEO from "components/SEO"
import Home from "modules/Home/Home"
import { IBlogPost } from "utils/types"

type DataProps = {
  allContentfulBlogPost: {
    nodes: IBlogPost[]
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Home posts={data.allContentfulBlogPost.nodes} />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [createdAt], order: DESC }) {
      nodes {
        id
        slug
        subtitle
        title
        description
        authorName
        captionText
        tags
        date(formatString: "DD MMMM YYYY")
        createdAt(formatString: "DD MMMM YYYY")
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        authorImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        body {
          raw
        }
      }
    }
  }
`
