import React, { useState } from "react"
import { IBlogPost } from "utils/types"
import BlogItem from "components/BlogItem"

interface BlogListingsProps {
  posts: IBlogPost[]
}

export default function BlogListings({ posts }: BlogListingsProps) {
  const [batch, setBatch] = useState(1)

  const postsPerBatch = 12
  const numberOfBatches = Math.ceil(posts.length / postsPerBatch)

  const postsShownCount = postsPerBatch * batch

  if (!posts.length) return null

  return (
    <div>
      <div className="mt-16">
        <h2 className="text-xl md:text-2xl font-medium mb-5 md:mb-10">
          All Blog Posts
        </h2>
        <div className="grid  md:grid-cols-2 xl:grid-cols-3 gap-x-12 gap-y-16">
          {posts.slice(0, postsShownCount).map(post => (
            <BlogItem key={post.id} post={post} />
          ))}
        </div>

        <hr className="mt-10" />

        <div className="mt-7 flex justify-center">
          {batch !== numberOfBatches && (
            <button
              onClick={() => setBatch(batch + 1)}
              className="border py-3 text-sm px-8 rounded-md bg-slate-50 hover:bg-slate-100 font-medium text-gray-700"
            >
              LOAD MORE
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
