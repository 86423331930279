import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { GoPrimitiveDot } from "react-icons/go"
import { IBlogPost } from "utils/types"
import Badge from "components/Badge"
import { convertBodyToHTMLString } from "utils/helpers"

interface OtherRecentPostsProps {
  posts: IBlogPost[]
}

export default function OtherRecentPosts({ posts }: OtherRecentPostsProps) {
  return (
    <div className="space-y-8 md:space-y-4 w-full">
      {posts.map(
        ({
          id,
          slug,
          authorName,
          date,
          createdAt,
          image,
          title,
          description,
          tags,
          body,
        }) => (
          <div key={id} className="flex flex-col md:flex-row md:space-x-6">
            <Link to={`/${slug}`} className="w-full md:w-1/2 shrink-0">
              {image && (
                <GatsbyImage
                  className="w-full h-56 shrink-0 mb-6 object-cover cursor-pointer"
                  alt={`Image for ${title}`}
                  image={image.gatsbyImageData}
                />
              )}
            </Link>
            <div className="space-y-4 pt-2 w-full">
              <div className="flex items-center text-sm space-x-1 mb-3 text-lime-600">
                <p>{authorName}</p>
                <GoPrimitiveDot />
                <p>{date || createdAt}</p>
              </div>

              <Link to={`/${slug}`}>
                <div className="flex cursor-pointer items-center mb-4 justify-between">
                  <h1 className="font-medium text-xl">{title}</h1>
                </div>
              </Link>

              <p className="threeLineClamp text-sm text-gray-500">
                {description || (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: convertBodyToHTMLString(body),
                    }}
                  />
                )}
              </p>

              <div className="flex flex-wrap mt-4 space-x-3">
                {tags?.slice(0, 2)?.map(tag => (
                  <Link to={`/tags/${tag}`}>
                    <Badge key={tag}>{tag}</Badge>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  )
}
