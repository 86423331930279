import React from "react"
import { IBlogPost } from "utils/types"
import BlogListings from "./components/BlogListings"
import FeaturedRecentPost from "./components/FeaturedRecentPost"
import OtherRecentPosts from "./components/OtherRecentPosts"

interface HomeProps {
  posts: IBlogPost[]
}

export default function Home({ posts }: HomeProps) {
  // TODO: This can be refactored
  const featuredRecent = posts[0]
  const otherRecent = posts.slice(1, 3)
  const otherListings = posts.slice(3, posts.length)

  return (
    <div className="flex my-32 px-[7%] justify-center">
      <div className="max-w-7xl w-full">
        <div className="flex items-center space-y-5 flex-col justify-center">
          <p className="text-lime-600">Our Blog</p>
          <h1 className="text-3xl md:text-5xl text-center font-bold">
            News <span className="font-mono italic font-normal">and</span>{" "}
            Updates
          </h1>
          <p className="text-base md:text-lg text-center text-gray-500">
            Find latest and relevant information about Emerald, it's features
            and cryptocurrency in general.
          </p>
        </div>

        <div className="mt-28">
          <h2 className="text-xl md:text-2xl font-medium mb-7 md:mb-10">
            Recent Blog Posts
          </h2>

          <div className="flex space-y-10 lg:space-y-0 lg:space-x-8 flex-col lg:flex-row">
            <FeaturedRecentPost post={featuredRecent} />
            <OtherRecentPosts posts={otherRecent} />
          </div>
        </div>

        <BlogListings posts={otherListings} />
      </div>
    </div>
  )
}
