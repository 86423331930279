import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FiArrowUpRight } from "react-icons/fi"
import { GoPrimitiveDot } from "react-icons/go"
import Badge from "components/Badge"
import { IBlogPost } from "utils/types"
import { convertBodyToHTMLString } from "utils/helpers"

interface FeaturedRecentPostProps {
  /** This is in assumption that there will at least be one post in the list */
  post: IBlogPost
}
export default function FeaturedRecentPost({ post }: FeaturedRecentPostProps) {
  if (!post) return null

  const {
    slug,
    authorName,
    createdAt,
    date,
    image,
    title,
    description,
    tags,
    body,
  } = post

  return (
    <div className="w-full">
      <Link to={`/${slug}`}>
        {image && (
          <GatsbyImage
            className="w-full h-64 mb-6 object-cover cursor-pointer"
            alt="most-recent-blog-image"
            image={image.gatsbyImageData}
          />
        )}
      </Link>
      <div className="flex items-center space-x-1 mb-3 text-lime-600">
        <p>{authorName}</p>
        <GoPrimitiveDot />
        <p>{date || createdAt}</p>
      </div>

      <Link to={`/${slug}`}>
        <div className="flex items-center cursor-pointer mb-4 justify-between">
          <h1 className="font-medium text-xl md:text-2xl">{title}</h1>
          <FiArrowUpRight fontSize="x-large" />
        </div>
      </Link>

      <p className="twoLineClamp text-gray-500">
        {description || (
          <span
            dangerouslySetInnerHTML={{ __html: convertBodyToHTMLString(body) }}
          />
        )}
      </p>

      <div className="flex mt-4 space-x-3">
        {tags?.map(tag => (
          <Link key={tag} to={`/tags/${tag}`}>
            <Badge key={tag}>{tag}</Badge>
          </Link>
        ))}
      </div>
    </div>
  )
}
